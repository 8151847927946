const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const formatBlogDate = (showDate: string) => {
  const date = new Date(showDate);
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const isRealCaption = (caption: string) =>
  ![".jpg", ".jpeg", ".jfif", ".webp", ".png", ".avif"].some((a) =>
    caption.includes(a)
  );
