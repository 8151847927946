import React from "react";
import tw from "twin.macro";
import { PageProps, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Button, SEO } from "../components";
import { getSnippetFromHtml } from "../utils/text-utils";
import { isRealCaption } from "../utils/helpers";

const CareersPost: React.FC<PageProps> = ({ data: { strapiJob } }) => {
  const {
    content,
    image,
    role,
    location: locationText,
    contactEmail,
  } = strapiJob;

  return (
    <>
      <SEO
        title={`Job - ${role} - ${locationText}`}
        description={getSnippetFromHtml(content.data.content)}
        previewImage={{ src: image.localFile.publicURL }}
      />
      <main>
        <section tw="relative py-16 bg-white overflow-hidden">
          <div tw="relative mx-auto max-w-prose px-4 sm:px-6 lg:px-8">
            <div tw="text-lg max-w-prose mx-auto">
              <span tw="block text-base text-pacific text-center tracking-wide uppercase">
                Careers
              </span>
              <h1 tw="mt-4 block text-5xl text-center font-display uppercase tracking-tight text-gray-900 sm:text-5xl letter-spacing[0.07em]">
                {role}
              </h1>
              <p tw="mt-8 text-xl text-gray-500 font-light leading-8 text-center">
                {locationText}
              </p>
            </div>
            <div tw="mt-20 mx-auto text-gray-500">
              <figure tw="rounded-lg">
                <GatsbyImage
                  image={getImage(image.localFile)}
                  alt={
                    image.alternativeText ||
                    image.caption ||
                    `${role} hero image`
                  }
                  tw="w-full rounded-lg"
                  imgStyle={{
                    borderRadius: "0.5rem",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                />
                {!!image.caption && isRealCaption(image.caption) && (
                  <figcaption tw="text-center">{image.caption}</figcaption>
                )}
              </figure>
              <div tw="prose font-light prose-lg mt-20 mx-auto">
                <div
                  className="strapi-html-content"
                  dangerouslySetInnerHTML={{ __html: content.data.content }}
                />
              </div>
            </div>
            <a
              href={`mailto:${contactEmail}?subject=${role} Application`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained"
                fullWidth
                customStyles={tw`block mt-8`}
              >
                Apply Now
              </Button>
            </a>
          </div>
        </section>
      </main>
    </>
  );
};

export default CareersPost;

export const query = graphql`
  query careersTemplateQuery($id: String!) {
    strapiJob(id: { eq: $id }) {
      contactEmail
      content {
        data {
          content
        }
      }
      updatedAt
      location
      slug
      role
      image {
        alternativeText
        caption
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
  }
`;
